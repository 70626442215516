html {
  height: 100%
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: auto;
  background: #fff5e4;
}

#root {
  height: 100%;
  background: #fff5e4;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.logo {
  margin: 5px;
  padding: 10px 20px;
  display: inline-block;
  font-family: 'Caveat', cursive;
  pointer-events: none;
  cursor: default;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: rgb(0, 0, 0);
  background: #fff5e4;
  font-family: "Gill Sans", sans-serif;
  width: 100vw;
}

nav {
  display: inline-block;
}

ul.list {
  display: flex;
  justify-content: flex-end;
  list-style: none;
  margin-right: 10px;
}

li.button {
  padding: 0 10px;
  margin-top: 14px;
}

li.normal {
  padding: 0 10px;
  margin-top: 20px;
}

.vlog {
  width: 100%;
  height: auto;
}

.video {
  width: 100%;
}

.video iframe {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.footer {
  font-family: cursive;
  display: flex;
  height: 80px;
  bottom: 0;
  margin-top: 40px;
  justify-content: center;
  align-items: center;
  color: #FFE3E1;
  background: #FF9494;
  width: 100vw;
}

.elementTitle {
  font-family: 'Caveat', cursive;
}

.element {
  padding: 10px;
}

.underNav {
  background: #FF9494;
  height: 70px;
}

.underNavNav {
  justify-content: center;
  height: 100%;
}

.navLink {
  color: #FFE3E1;
  font-family: 'Caveat', cursive;
  height: 100%;
  line-height: 50px;
}
